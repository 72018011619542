import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [3];

export const dictionary = {
		"/": [4],
		"/about": [5,[2]],
		"/about/community": [6,[2]],
		"/about/features": [7,[2]],
		"/about/features/agents": [8,[2]],
		"/about/features/documents": [9,[2]],
		"/about/features/knowledge-bases": [10,[2]],
		"/about/features/models": [11,[2]],
		"/about/features/tools": [12,[2]],
		"/about/guides": [13,[2]],
		"/about/humans": [14,[2]],
		"/about/insides": [15,[2]],
		"/about/platform/context": [16,[2]],
		"/about/privacy": [17,[2]],
		"/about/subscriptions": [18,[2]],
		"/about/terms": [19,[2]],
		"/sign-in": [20],
		"/tttest/sentry-example": [34],
		"/tt": [~21,[3]],
		"/tt/account": [~22,[3]],
		"/tt/account/payment/success": [23,[3]],
		"/tt/account/updatePassword": [24,[3]],
		"/tt/admin/odin": [~25,[3]],
		"/tt/conversations": [~26,[3]],
		"/tt/conversations/create": [~28,[3]],
		"/tt/conversations/[id]": [~27,[3]],
		"/tt/library": [~29,[3]],
		"/tt/library/documents": [~30,[3]],
		"/tt/library/documents/import": [~32,[3]],
		"/tt/library/documents/new": [~33,[3]],
		"/tt/library/documents/[id]": [~31,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';